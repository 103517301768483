.moduleWrapper {
  :global {
    .labelDate {
      display: flex;
      width: 100%;
      justify-content: space-between;
      flex-direction: row;
    }

    .quickFilter {
      display: flex;
      gap: 10px;
      div {
        cursor: pointer;
        &:hover {
          color: #d97100;
        }
      }
    }

    .formFieldDate label {
      width: 100%;
    }
  }
}
