.moduleWrapper {
  // Grid col 2
  //display: grid;
  //grid-template-columns: 1fr 1fr;
  //grid-gap: 20px;
  //margin: 0 auto;
  //max-width: 1200px;
  //padding: 20px;

  :global {
    .likeBtn {
      width: 40px;
      height: 40px;
      display: flex;
      flex-direction: column;
      align-content: center;
      justify-content: center;
      align-items: center;
      position: fixed;
      bottom: 10px;
      background: #FFF;
      border-radius: 50%;
      left: 20px;
      cursor: pointer;

      .counter {
        margin-top: 3px;
        color: #FFF;
        position: absolute;
        font-size: 10px !important;
      }
    }
    .image {
      padding-bottom: 10px;
      margin: 0 auto;
      //width: 210mm;
      max-width: 100%;
    }

    img {
      margin: 0 auto;
      max-width: 100%;
    }

    .blogDate {
      text-align: center;
      margin-bottom: 10px;
      color: #000000;
    }
  }
}
