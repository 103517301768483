.moduleWrapper {
  :global {
    .ctaActions {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;

      button {
        padding: 30px 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 300px;
      }
    }
  }
}
