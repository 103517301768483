.moduleWrapper {
  :global {
    .twoColumns {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      column-gap: 20px;

      .submitRow {
        width: 100%;
      }

      > div {
        width: calc(50% - 10px);
      }
    }

    .manualLayout {
      .row {
        display: flex;
        flex-direction: row;
        column-gap: 20px;
      }

      .left, .right {
        width: calc(50% - 10px);
      }

      .full {
        width: calc(100%);
      }
    }

    .commonTable {
      gap: 10px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .tableTopActions {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      border-bottom: 1px solid #ddd;
      padding-bottom: 10px;

      .title {
        display: flex;
        gap: 10px;
        flex-direction: row;
        align-items: center;
      }
    }

    .tablePagination {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      width: 100%;
      margin-top: 10px;
    }

    .tableContent {
      width: 100%;
      border-collapse: collapse;

      thead {
        background: #e7e7e7;
      }

      thead tr th.no,
      tbody tr td.no {
        width: 20px;
        text-align: center;
      }

      thead tr th.actions,
      tbody tr td.actions {
        width: 100px;
        text-align: right;
      }

      th, td {
        padding: 5px 10px;
        text-align: left;
        border: 1px solid #dbdbdb;
        font-size: 14px;
      }
    }
  }
}
