.moduleWrapper {
  font-family: Tahoma, sans-serif;
  font-size: 16px;

  :global {
    .wrapper {
      display: flex;
      flex-direction: row;
      height: 100vh;
      background: #f7f7f7;

      .sidebar {
        width: 200px;
        background: #ebebeb;
        box-sizing: border-box;
        border-right: 1px solid #b9b9b9;

        .logo {
          text-transform: uppercase;
          text-align: center;
          font-size: 16px;
          height: 44px;
          display: flex;
          background: #2d2d2d;
          justify-content: center;
          align-items: center;
          flex-direction: row;

          a {
            display: flex;
            color: #FFF;
            text-decoration: none;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 10px;
          }
        }

        .user {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 15px;
          padding: 15px 0px;

          .logout {
            cursor: pointer;
            display: flex;
          }
        }

        .links {
          display: flex;
          flex-direction: column;
          //gap: 10px;

          .link a {
            //border-top: 1px solid #b9b9b9;
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: flex-start;
            flex-direction: row;
            text-decoration: none;
            color: #313131;
          }

          .link a {
            padding: 15px 20px 15px 15px;
            cursor: pointer;
          }

          .link a.selected {
            background: #dadada;
            border-top: 1px solid #b9b9b9;
            border-bottom: 1px solid #b9b9b9;
          }

          .link a:hover {
            background: #dadada;
          }

          .link:hover a {
            color: #2d2d2d;
          }

        }
      }

      .content {
        width: calc(100% - 200px);

        .pageWrapper {
          height: calc(100vh - 45px);
          overflow: auto;
          padding: 0 20px;
        }

        .pageHeading {
          display: flex;
          font-size: 20px;
          padding: 10px 20px;
          background: #2d2d2d;
          color: #FFF;
          flex-direction: row;
          justify-content: space-between;

          .left, .right {
            width: 200px;
          }

          .right {
            cursor: pointer;
            display: flex;
            font-size: 15px;
            text-align: right;
            align-items: center;
            justify-content: flex-end;
          }

          .pageTitle {
            text-align: center;
          }
        }

        .noContent {
          width: 100%;
          height: 50px;
          text-align: center;
          background: #ededed;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .tableSearch {
          width: 100%;

          form {
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            column-gap: 20px;
          }

          .submitRow {
            width: 100% !important;

            .ant-form-item-control-input-content {
              flex: auto;
              max-width: 100%;
              display: flex;
              align-items: center;
              justify-content: center;

              button {
                padding: 0 50px;
              }
            }
          }

          .threeColumns {
            .ant-form-item {
              width: calc(100% / 3 - (2 * 20px) / 3);
            }
          }

          .fourColumns {
            .ant-form-item {
              width: calc(100% / 4 - (3 * 20px) / 4);
            }
          }
        }

        .pageContent {
          height: calc(100% - 44px);
          overflow: auto;
          padding: 20px 20px;
          box-sizing: border-box;
          max-width: 1600px;
          background: #fff;
          margin: 0 auto;
          margin-top: 20px;
          border-radius: 5px;
        }
      }
    }
  }
}
