.moduleWrapper {
  :global {
    .deviceStatuses {
      margin-left: -10px;

      .deviceStatus {
        display: flex;
        align-items: center;
        flex-direction: row;
        gap: 10px;

        &:not(:last-child) {
          border-bottom: 1px solid #cecece;
          padding-bottom: 5px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
