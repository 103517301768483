.moduleWrapper {
  :global {
    .noDevices {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 18px;
      background: #ededed;
      border-radius: 10px;
      box-sizing: border-box;
    }
  }
}
