.ant-btn-primary {
    background-color: #2d2d2d !important;
    color: #FFF !important;
}

.ant-btn-primary:hover {
    background-color: #2d2d2d !important;
    color: #FFF !important;
    opacity: 0.8;
    border: 1px solid #000;
}

.ant-btn-primary {
    box-shadow: none !important;
}

html, body {
    padding: 0 0;
    margin: 0 0;
}

body .loadingOverlay {
    display: none;
}

body[loading=true] .loadingOverlay {
    width: 100%;
    height: 100%;
    background: #00000052;
    z-index: 11111111;
    position: fixed;
    display: block !important;
}

body[loading=true] .loadingOverlay .content {
    text-align: right;
    padding: 85px 25px 0 0;
}

.ant-upload-list-item {
    display: none;
}

.ant-upload-drag {
    height: 100px !important;
}

.ant-upload-list-item {
    display: none !important;
}

.previewArea {
    background: #f1f1f1;
    border-radius: 10px;
    margin-bottom: 10px;
}

.hiddenImageField .ant-form-item-control-input {
    display: none !important;
}

.ant-upload-text {
    padding: 0 20px;
}

tr.todayRecord {
    background: #ffecc9;
}


@media screen and (max-width: 1100px) {
    .pcOnly {
        display: none !important;
    }

    .sidebar {
        width: 0px !important;
        overflow: hidden;
    }

    .pageHeading {
        justify-content: center !important;
    }

    .pageHeading .left,
    .pageHeading .right {
        display: none !important;
    }

    .content {
        overflow: auto;
        width: 100% !important;
    }

    .ctaActions {
        flex-direction: column !important;
    }
}

@media screen and (min-width: 1101px) {
    .mobileOnly {
        display: none;
    }
}

.flexCenter {
    justify-content: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}


.flexCenterLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

input, textarea {
    font-size: 16px !important;
}


body[type=blog] .sidebar {
    display: none !important;
}

body[type=blog] .content {
    width: 100% !important;
}

body[type=blog] .pageContent {
    height: auto !important;
}

body[type=blog] .pageWrapper,
body[type=blog] .pageContent {
    background: #9d9d9d !important;
}

body[type=blog] .pageWrapper {
    height: calc(100vh - 60px) !important;
}

body[type=blog] .pageHeading {
    font-size: 30px !important;
}

@media screen and (max-width: 1024px) {
    body[type=blog] .pageWrapper {
        padding: 0 0 !important;
    }

    body[type=blog] .pageContent {
        margin-top: 0px !important;
        padding: 10px 10px !important;
    }
}
